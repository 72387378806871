const ppa5 = `
%%{init: {'mirrorActors': true, 'height':38, 'width': 35}}%%
sequenceDiagram
    Participant User
    Participant Platform
    Participant Publisher
    User->>Platform: send conversion_token
    Platform->>User: issue iou_token
    Note over User,Platform: iou_token: publisher_id
    User->>Publisher: send iou_token
    
    `;

export default ppa5;