import React from "react"
import FaceBubble from "../FaceBubble"
import Footer from "../Footer"
import MyWork from "./MyWork"

const Work = () => (
  <div>
    <FaceBubble />
    {/* <About /> */}
    <MyWork/>
    <Footer showNext={false} prevItem={"./"} nextItem={"./"} />
  </div>
)

export default Work
