import React from "react";
import { NavLink } from "react-router-dom";
import * as Icons from "heroicons-react";

// const ImageCard = ({ item }) => {
//   return (
//     <div
//       key={item.id}
//       className="flex flex-col max-w-sm mb-8 overflow-hidden border-2 border-gray-100 rounded-2xl hover:shadow-lg hover:bg-white"
//       style={{ transition: "all .3s ease" }}
//     >
//       <NavLink to={item.navLink}>
//         <div className="flex-grow group main">
//           <img
//             src={item.image}
//             alt={item.title}
//             className="object-cover w-full h-64 bg-center main_image"
//           />
//           <div className="px-6 py-4">
//             <div className="flex justify-between mb-2">
//               <div className="text-xl font-bold text-blue-500">
//                 {item.title}
//               </div>
//               {/* Date to the right of the title */}
//               <div className="flex items-center space-x-2">
//                 <Icons.CalendarOutline size={24} className="text-gray-500" aria-label="Date" />
//                 <span className="text-sm font-medium text-gray-700">{item.date}</span>
//               </div>
//             </div>
//             <ul>
//               <li>
//                 <p className="semibold">{item.subtitle}</p>
//               </li>
//             </ul>
//           </div>
//           <div className="hidden text-4xl main_text group-hover:inline-block">
//             <button
//               className="p-4 text-lg font-semibold text-center bg-white rounded-lg hover:bg-blue-500 hover:text-white"
//               style={{ transition: "all .25s ease" }}
//             >
//               Learn more
//             </button>
//           </div>
//         </div>
//         <div className="px-6 py-4">
//           {item.category.split(",").map((tag) => (
//             <span className="inline-block px-3 py-1 mr-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded-full">
//               {tag}
//             </span>
//           ))}
//         </div>
//       </NavLink>
//     </div>
//   );
// };

const ImageCard = ({ item }) => {
  return (
    <div
      key={item.id}
      className="flex flex-col max-w-sm mb-8 overflow-hidden border-2 border-gray-100 rounded-2xl hover:shadow-lg hover:bg-white"
      style={{ transition: "all .3s ease" }}
    >
      <NavLink to={item.navLink} className="flex flex-col justify-between flex-grow">
        <div>
          <div className="relative group">
            <img
              src={item.image}
              alt={item.title}
              className="object-cover w-full h-64 bg-center main_image"
            />
            <div className="absolute inset-0 flex items-center justify-center hidden text-4xl main_text group-hover:flex">
              <button
                className="p-4 text-lg font-semibold text-center bg-white rounded-lg hover:bg-blue-500 hover:text-white"
                style={{ transition: "all .25s ease" }}
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="px-6 py-4">
            <div className="flex justify-between mb-2">
              <div className="text-xl font-bold text-blue-500">
                {item.title}
              </div>
              {/* Date to the right of the title */}
              <div className="flex items-center space-x-2">
                <Icons.CalendarOutline size={24} className="text-gray-500" aria-label="Date" />
                <span className="text-sm font-medium text-gray-700">{item.date}</span>
              </div>
            </div>
            <ul>
              <li>
                <p className="semibold">{item.subtitle}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="px-6 py-4">
          {item.category.split(",").map((tag) => (
            <span className="inline-block px-3 py-1 mr-2 text-sm font-semibold text-gray-700 bg-gray-200 rounded-full">
              {tag}
            </span>
          ))}
        </div>
      </NavLink>
    </div>
  );
};

export default ImageCard;

// <!-- component -->
// <div className="py-4">
//     <div className="container flex items-center justify-center max-w-sm mx-auto bg-white rounded-md shadow-lg group content-div">
//         <div>
//         <div  className="w-full image-cover rounded-t-md" >
//           <div className="float-right w-16 h-16 p-2 m-4 text-center text-white bg-gray-700 rounded-full fd-cl group-hover:opacity-25">
//              <span className="font-sans text-base font-bold tracking-wide border-b border-white"> 12</span>
//               <span className="block font-sans text-xs font-bold tracking-wide uppercase">April</span>
//           </div>
//         </div>
//         <div className="px-4 py-8 bg-white rounded-b-md fd-cl group-hover:opacity-25">
//             <span className="block text-lg font-bold tracking-wide text-gray-800">Book a flight</span>
//             <span  className="block text-sm text-gray-600">Vivamus ac ligula sit amet erat luctus laoreet ac quis ligula. Donec bibendum faucibus purus eget cursus. Proin enim ante, scelerisque vel sem sit amet, ultrices mollis risus. Praesent justo felis, ullamcorper a cursus sed, condimentum at dui.
//             </span>
//         </div>
//         </div>
//         <div className="absolute opacity-0 fd-sh group-hover:opacity-100">
//        <span className="font-sans text-3xl font-bold leading-relaxed tracking-wider text-white">Paris city of light</span>
//        <div className="pt-8 text-center">
//            <button className="p-4 text-lg font-bold text-center text-gray-700 bg-white rounded-lg">Learn more</button>
//            </div>
//     </div>
//     </div>

// </div>
// <style>
//   .content-div{
//   background-image:url('https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80');
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position:center;
// }
//   .content-div:hover{
//     background-image:
//     linear-gradient(to right,
//      rgba(126, 213, 111, 0.801), hsla(160, 64%, 43%, 0.801) ),
//      url('https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80');
//     }

//     .image-cover  {
//       height:260px;
//     }
//   /*
//   -remove the classes below if you have the 'group-hover'property added in your tailwind config file
//   -also remove the class from the html template
//   */
//     .content-div:hover .fd-cl{
//  opacity: 0.25;
//     }
//     .content-div:hover .fd-sh{
//  opacity: 1;
//     }

//   </style>
