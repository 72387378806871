import React from "react"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { animateScroll as scroll } from "react-scroll"
import { ChevronDoubleUpOutline } from "heroicons-react"
// import { HeartOutline } from "heroicons-react"
import { ChevronRightOutline } from "heroicons-react"
import { Link } from "react-router-dom"
import { ChevronLeftOutline } from "heroicons-react"

function scrollToTop() {
  scroll.scrollToTop()
}

const Footer = ({ navItems, nextItem, prevItem, showNext }) => {
  if (showNext === true) {
    return (
      <div>
        <div className="container flex flex-col max-w-2xl py-6 mx-auto leading-7 text-left">
          <div className="flex flex-row justify-between p-8">
            <Link
              to={prevItem}
              className="flex flex-col items-center cursor-pointer hover:text-blue-500"
            >
              <ChevronLeftOutline />
              <p>Previous</p>
            </Link>
            <div
              className="flex flex-col items-center cursor-pointer hover:text-blue-500"
              onClick={scrollToTop}
            >
              <ChevronDoubleUpOutline />
              <p>Scroll to top</p>
            </div>

            <Link
              to={nextItem}
              className="flex flex-col items-center cursor-pointer hover:text-blue-500"
            >
              <ChevronRightOutline />
              <p>Next</p>
            </Link>
          </div>
        </div>
        {/* <div className="grid grid-cols-2">
          <div className="flex flex-col items-start">
            <p className="p-6 text-sm font-semibold text-center">
              Made from scratch with react and{" "}
              <FontAwesomeIcon icon={faHeart} size="sm" color="red" />
            </p>
          </div>
          <div className="flex flex-col items-end p-6 text-sm font-semibold">
            © {new Date().getFullYear()}
          </div>
        </div> */}
      </div>
    )
  } else {
    return (
      <div>
        <div className="container flex flex-col max-w-2xl py-6 mx-auto leading-7 text-left">
          <div className="flex flex-row justify-between p-8">
            <Link
              // to={prevItem}
              className="flex flex-col items-center cursor-pointer hover:text-blue-500"
            >
              {/* <ChevronLeftOutline />
            <p>Previous</p> */}
            </Link>
            <div
              className="flex flex-col items-center cursor-pointer hover:text-blue-500"
              onClick={scrollToTop}
            >
              <ChevronDoubleUpOutline />
              <p>Scroll to top</p>
            </div>

            <Link
              // to={nextItem}
              className="flex flex-col items-center cursor-pointer hover:text-blue-500"
            >
              {/* <ChevronRightOutline />
            <p>Next</p> */}
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex flex-col items-start">
            <p className="p-6 text-sm font-semibold text-center">
              Made in Providence with{" "}
              <FontAwesomeIcon icon={faHeart} size="sm" color="red" />
            </p>
          </div>
          <div className="flex flex-col items-end p-6 text-sm font-semibold">
            © {new Date().getFullYear()}
          </div>
        </div>
      </div>
    )
  }
}

export default Footer

// <div className="relative pb-4 mt-16 ">
// <div className="container px-4 mx-auto mt-8">
//   <div className="flex flex-wrap items-center justify-center md:justify-between">
//     <div className="w-full md:w-6/12">
//       <p className="pt-4 text-sm font-semibold text-black">
//         Made from scratch with react and{" "}
//         <FontAwesomeIcon icon={faHeart} size="sm" color="red" />
//       </p>
//     </div>
//     <div className="w-full px-4 md:w-6/12">
//       <ul className="flex flex-wrap justify-center pt-4 list-none md:justify-end">
//         {navItems.map((nav) => (
//           <li>
//             <NavLink
//               to={`${nav}`}
//               className="flex flex-wrap justify-center list-none md:justify-end"
//             >
//               <span className="block px-3 py-1 text-sm font-semibold text-black hover:text-gray-400">
//                 {nav}
//               </span>
//             </NavLink>
//           </li>
//         ))}
//       </ul>
//     </div>
//   </div>
// </div>
// </div>
