import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { Link, NavLink } from "react-router-dom"

export default function Navbar({ navItems }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 mb-3 navbar-expand-md">
        <div className="container flex flex-wrap items-center justify-between px-1 mx-auto">
          <div className="relative flex justify-between w-full md:w-auto md:static md:block md:justify-start">
            <Link
              className="inline-block py-2 mr-4 font-bold leading-relaxed tracking-wider uppercase whitespace-no-wrap text-md hover:text-blue-500"
              to="/"
            >
              Benjamin Kilimnik
            </Link>
            <button
              className="block px-3 py-1 text-xl leading-none bg-transparent border border-transparent border-solid rounded outline-none cursor-pointer md:hidden focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <div
            className={
              "md:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col list-none md:flex-row md:ml-auto">
              {navItems.map((nav) => (
                <li id={nav.id} className="hover:text-blue-500">
                  <NavLink
                    className="flex items-center px-4 py-2 leading-snug"
                    to={`${nav.navItem}`}
                  >
                    <span className="tracking-wider text-md">
                      {nav.navItem}
                    </span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
