const ppa1 = `
%%{init: {'mirrorActors': true, 'height':38, 'width': 35}}%%
sequenceDiagram
Participant User
Participant Platform
Platform->>User: pre-seeds user_token
Note over User,Platform: user_token: hidden_user_id, not_sybil
    
    `;

export default ppa1;