const ppa4 = `
%%{init: {'mirrorActors': true, 'height':38, 'width': 35}}%%
sequenceDiagram
    Participant User
    Participant Advertiser
    Participant Platform
    User->>Advertiser: send ad_impression_token
    Advertiser->>User: issue conversion_token
    Note over Advertiser,User: conversion_token: hidden_publisher_id, ad
    User->>Platform: send conversion_token for validation
    
    `;

export default ppa4;