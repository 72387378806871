import React, { Component } from "react"
import ImageCard from "./ImageCard"
// import { ChevronDown } from "heroicons-react"

class FilteredList extends Component {
  state = {
    category: "all",
    itemsToShow: this.props.allItems,
    catFuns: [
      { catFun: this.catAll, label: "all" },
      { catFun: this.catCode, label: "code" },
      // { catFun: this.catUi, label: "ui / ux" },
      { catFun: this.catEngineering, label: "engineering" },
      { catFun: this.catJournalism, label: "journalism" },
      { catFun: this.catDesign, label: "design" },
      { catFun: this.catPhotography, label: "photography" },
    ],
    isOpen: false,
  }

  // alter category property of the state in response to dropdown selections
  categoryGenerator = (g) => (e) => {
    this.setState({
      category: g,
    })
    this.refresh(g)
  }

  refresh = (category) => {
    let toRefresh = this.props.allItems

    // filter the state by category
    if (category === "code") {
      toRefresh = toRefresh.filter((i) => i.category.includes("code"))
    } else if (category === "ui / ux") {
      toRefresh = toRefresh.filter((i) => i.category.includes("ui"))
    } else if (category === "journalism") {
      toRefresh = toRefresh.filter((i) => i.category.includes("journalism"))
    } else if (category === "design") {
      toRefresh = toRefresh.filter((i) => i.category.includes("design"))
    } else if (category === "photography") {
      toRefresh = toRefresh.filter((i) => i.category.includes("photography"))
    } else if (category === "engineering") {
      toRefresh = toRefresh.filter((i) => i.category.includes("engineering"))
    }

    this.setState({
      itemsToShow: toRefresh,
    })
  }

  catAll = this.categoryGenerator("all")
  catCode = this.categoryGenerator("code")
  catUi = this.categoryGenerator("ui / ux")
  catJournalism = this.categoryGenerator("journalism")
  catDesign = this.categoryGenerator("design")
  catPhotography = this.categoryGenerator("photography")
  catEngineering = this.categoryGenerator("engineering")

  toggleDropdown = (e) => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  closeDropdown = (e) => {
    this.setState({ isOpen: false })
  }

  render() {
    const catFuns = [
      { catFun: this.catAll, label: "all" },
      { catFun: this.catCode, label: "code" },
      // { catFun: this.catUi, label: "ui / ux" },
      // { catFun: this.catJournalism, label: "journalism" },
      { catFun: this.catDesign, label: "design" },
      { catFun: this.catEngineering, label: "engineering" },
      // { catFun: this.catPhotography, label: "photography" },
    ]

    return (
      <div>
        <div id="work" className="grid grid-rows-1 pb-12 md:flex md:justify-center md:align-center justify-items-center">
          {catFuns.map((item) => (
            <button
              // formerly indigo:
              // className="px-6 py-3 mx-2 mb-1 text-sm font-bold text-indigo-500 uppercase bg-transparent border border-indigo-500 border-solid rounded outline-none hover:bg-indigo-500 hover:text-white active:bg-indigo-600 focus:outline-none"
              className="px-6 py-3 mx-2 mb-1 text-sm font-bold text-blue-500 uppercase bg-transparent border border-blue-500 border-solid outline-none rounded-2xl hover:bg-blue-500 hover:text-white focus:outline-none focus:text-white focus:bg-blue-500"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={item.catFun}
            >
              {item.label}
            </button>
          ))}
          {/* <div>
            <div className="relative">
              <button
                onClick={this.toggleDropdown}
                // className="relative z-10 block w-8 h-8 overflow-hidden border-2 border-gray-600 rounded-full focus:outline-none focus:border-white"
                className="px-4 py-3 text-sm font-bold text-blue-500 uppercase border border-blue-500 rounded shadow-md outline-none hover:text-white hover:bg-blue-500 hover:shadow-lg focus:outline-none"
              >
                <div className="flex flex-row items-center gap-2">
                  {this.state.category}
                  <ChevronDown />
                </div>
              </button>

              <div
                className={
                  this.state.isOpen
                    ? "absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-2xl"
                    : "hidden"
                }
              >
                {catFuns.map((item) => (
                  <a
                    onClick={() => {
                      item.catFun()
                      this.closeDropdown()
                    }}
                    className="block px-4 py-2 text-gray-800 rounded-sm cursor-pointer hover:bg-blue-500 hover:text-white"
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            </div>
          </div> */}
        </div>
        <div className="grid grid-cols-1 gap-2 p-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 4xl:grid-cols-5 justify-items-center">
          {this.state.itemsToShow.map((item) => (
            <ImageCard key={item.id} item={item}></ImageCard>
          ))}
        </div>
      </div>
    )
  }
}

export default FilteredList

  /* <button
                className={
                  this.state.isOpen
                    ? " cursor-default bg-black opacity-50 fixed inset-0 w-full h-full"
                    : "hidden"
                }
                onClick={this.closeDropdown}
                tabIndex="-1"
              /> */
