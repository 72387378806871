import React, { Component } from "react"
import FilteredList from "../FilteredList"
// import Footer from "../Footer"

import { allItems } from "../../App"

class MyWork extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        {/* <h1 className="pt-12 pb-24 text-6xl text-center">My Work</h1> */}
        <FilteredList allItems={allItems} />
        {/* <Footer showNext={false} prevItem={"./"} nextItem={"./"} /> */}
      </div>
    )
  }
}

export default MyWork
