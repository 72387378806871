import React, { Component } from "react";
import { systems2, systems3 } from "../images";
import Footer from "../Footer";
import SideToc from "../SideToc";
import * as Icons from "heroicons-react";

class Systems extends Component {
  state = {
    showToc: true,
    showQuickLinks: false,
  };
  toggleToc = (e) => {
    this.setState({ showToc: !this.state.showToc });
  };
  toggleQuickLinks = () => {
    this.setState({ showQuickLinks: !this.state.showQuickLinks });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const TocItems = [
      {
        header: "K9db",
        id: "k9db",
        icon: () => <Icons.DatabaseOutline size={22} />,
        // size={24}
      },
      {
        header: "Database Proxy",
        id: "proxy",
        icon: () => <Icons.ServerOutline />,
      },
      {
        header: "Variable Ownership",
        id: "variable",
        icon: () => <Icons.HandOutline size={22} />,
      },
      // {
      //   header: "Pull Requests",
      //   id: "pr",
      //   icon: () => <Icons.AnnotationOutline size={22} />,
      // },
      // {
      //   header: "Storyboard",
      //   id: "storyboard",
      //   icon: () => <Icons.StarOutline size={22} />,
      // },
      // {
      //   header: "Conclusion",
      //   id: "conclusion",
      //   icon: () => <Icons.BookmarkOutline size={22} />,
      // },
    ];
    return (
      //       {content.map((item) => (
      //         <div className="container flex flex-col items-center justify-center max-w-2xl py-6 mx-auto leading-7 text-left">
      //         (header !== "" ?){<h1 className=`{item.headerType}`>{item.header}</h1>}
      // </div>
      //       ))}
      <>
        <div className="grid grid-cols-3">
          <SideToc
            TocItems={TocItems}
            toggleToc={this.toggleToc}
            showToc={this.state.showToc}
            className={this.state.showToc ? "md:col-span-1" : "hidden"}
          />
          <div
            className={
              this.state.showToc
                ? "col-span-full md:col-start-2 md:col-end-4"
                : "col-span-full"
            }
          >
            <div className="container flex flex-col items-center justify-center max-w-2xl px-4 py-6 mx-auto leading-7 text-left">
              <h1 className="self-start py-6 text-4xl">
                Privacy by Construction
              </h1>

              {/* Collapsible Quick Links Box */}
              <div className="self-start py-2">
                <button className="w-full text-left" onClick={this.toggleQuickLinks}>
                  <div className="flex items-center justify-between p-2 mb-2 text-blue-700 bg-blue-100 border-l-4 border-blue-500">
                    <p className="font-semibold">Quick Links</p>
                    <Icons.ChevronDownOutline size={24} className={`${this.state.showQuickLinks ? 'transform rotate-180' : ''}`} />
                  </div>
                </button>
                <div className={`pl-2 ${this.state.showQuickLinks ? 'block' : 'hidden'}`} style={{ minHeight: '132px' }}>
                  <a
                    href="https://github.com/brownsys/K9db"
                    target="_blank"
                    rel="noreferrer"
                    className="block py-2 text-blue-800 hover:text-blue-500"
                  >
                    K9db on GitHub
                  </a>
                  <a
                    href="https://www.usenix.org/conference/osdi23/presentation/albab"
                    target="_blank"
                    rel="noreferrer"
                    className="block py-2 text-blue-800 hover:text-blue-500"
                  >
                    OSDI '23 Presentation
                  </a>
                  <a
                    href="https://repository.library.brown.edu/studio/item/bdr:b3zu4t46/"
                    target="_blank"
                    rel="noreferrer"
                    className="block py-2 text-blue-800 hover:text-blue-500"
                  >
                    Variable Data Ownership Paper
                  </a>
                  <a
                    href="https://repository.library.brown.edu/studio/item/bdr:b3zu4t46/"
                    target="_blank"
                    rel="noreferrer"
                    className="block py-2 text-blue-800 hover:text-blue-500"
                  >
                    Research Symposium Presentation
                  </a>
                </div>
              </div>

              <p className="py-4">
                Be it the EU's General Data Protection Regulation or California's California Consumer Privacy Act (CCPA), governments have enacted a flurry of new laws seeking to protect people's privacy by regulating the use of Personally Identifiable Information (PII). Despite steep fines, compliance with these laws in the software industry has been spotty at best; privacy breaches abound and companies are paying millions as a result.
                Data privacy laws like the EU's GDPR grant users new rights, such as the right to request access to and deletion of their data. Manual compliance with these requests is error-prone and imposes costly burdens especially on smaller organizations, as non-compliance risks steep fines. The need for automated compliance is clear, but existing database systems are ill-equipped to provide it.
              </p>
              <h2 id="k9db" className="self-start py-4 text-3xl">
                K9db: Privacy-Compliant Storage for Web Applications by Construction
              </h2>
              <p className="py-4">
                K9db is a new, MySQL-compatible database that complies with privacy laws by construction. The core idea is to make the data ownership and sharing semantics explicit in the storage system. K9db has been

                {" "}
                <a
                  className="text-blue-800 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/brownsys/K9db"
                >
                  open-sourced
                </a>{" "}
                and accepted to
                {" "}
                <a
                  className="text-blue-800 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.usenix.org/conference/osdi23/presentation/albab"
                >
                  OSDI '23
                </a>{" "}
              </p>

              <div className="py-4">
                <div className="p-2 mb-2 text-blue-700 bg-blue-100 border-l-4 border-blue-500">
                  <p className="font-semibold">What was your role in this?</p>
                </div>
                <div>
                  <p>I started working on K9db in April 2021 and co-authored the paper published at OSDI'23.
                    Over that time period, I worked on several different aspects of the system, including building the database proxy that makes K9db compatible with MySQL, implementing variable data ownership to support complex privacy policies, designing test infrastructure, and benchmarking sample applications.
                  </p>
                </div>
              </div>
              {/* <p className="py-4">
                This requires K9db to capture and enforce applications' complex data ownership and sharing semantics, but in exchange simplifies privacy compliance. Using a small set of schema annotations, K9db infers storage organization, generates procedures for data retrieval and deletion, and reports compliance errors if an application risks violating the GDPR.
                Our K9db prototype successfully expresses the data sharing semantics of real web applications, and guides developers to getting privacy compliance right. K9db also matches or exceeds the performance of existing storage systems, at the cost of a modest increase in state size.
              </p> */}
              <h2 id="proxy" className="self-start py-4 text-2xl">
                Database Proxy
              </h2>
              <p className="py-2">
                Unlike a traditional relational database, K9db structures data
                primarily by user. This allows web applications to service GDPR-style requests without having to rely on error-prone custom
                scripts.
              </p>
              <div className="my-4 card">
                <img src={systems2} alt="Systems1" />
                <div className="m-4">
                  <span className="block text-lg text-center truncate">
                    Most databases versus K9db
                  </span>
                  {/* <span className="block mt-2 text-center text-gray-600 text-md">
                    Privacy law compliant by design
                  </span> */}
                </div>
              </div>
              <p className="py-4">
                One of my first contributions was a database proxy designed to make K9db compatible with existing applications that use a MySQL database.
                This enables developers using MySQL to gain the privacy benefits of K9db without having to rewrite their application code.
                The proxy can also be extended to support other widely used relational databases.
              </p>
              {/* <div className="py-6">
                <a
                  href="https://repository.library.brown.edu/studio/item/bdr:b3zu4t46/"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block mx-2 text-blue-500 transition duration-500 ease-out transform bg-white border-2 border-blue-500 hover:shadow-inner hover:scale-125 btn hover:bg-blue-500 hover:text-white"
                // className="inline-block transition duration-500 ease-out transform bg-white border-2 hover:shadow-inner hover:scale-125 btn text-primary border-primary hover:bg-primary hover:text-white"
                >
                  Research Presentation
                </a>
                <a
                  href="https://systems.cs.brown.edu/projects/gdpr/"
                  target="_blank"
                  rel="noreferrer"
                  // className="inline-block mx-2 text-blue-500 transition duration-500 ease-out transform bg-white border-2 border-blue-500 hover:shadow-inner hover:scale-125 btn hover:bg-blue-500 hover:text-white"
                  className="inline-block transition duration-500 ease-out transform bg-white border-2 hover:shadow-inner hover:scale-125 btn text-primary border-primary hover:bg-primary hover:text-white"
                >
                  Research Group
                </a>
              </div> */}
              <p className="py-2">
                The proxy converts MySQL queries into C/C++ compatible types and
                invokes a Foreign Function Interface (FFI) to call K9db’s
                database API. Query responses from K9db are converted back
                into MySQL compatible types which the web application can use.
                The proxy is designed to support multiple concurrent client
                connections - it is multi-threaded and synchronized.
                More details and performance benchmarks can be found in this {" "}
                <a
                  className="text-blue-800 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                  href="https://repository.library.brown.edu/studio/item/bdr:b3zu4t46/"
                >
                  research symposium presentation
                </a>.
              </p>
              <div className="w-9/12 my-4 card">
                <img src={systems3} className="" alt="Systems1" />
                <div className="m-4">
                  <span className="block text-lg text-center truncate">
                    MySQL {"<->"} K9db Proxy
                  </span>
                </div>
              </div>
              <h2 id="variable" className="self-start py-4 text-2xl">
                Variable Data Ownership
              </h2>
              <p className="py-2">
                I implemented variable data ownership to support the privacy policies of Shuup, an open-source e-commerce
                platform that allows users to request anonymization of their data. More details can be found in this {" "}
                <a
                  className="text-blue-800 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                  href="https://drive.google.com/file/d/14bW55IO0qGSb7NnqdARJC9wKmpTBwAN5/view"
                >
                  research paper
                </a>.
              </p>
            </div>
            <Footer
              showNext={true}
              prevItem={"./ppa"}
              nextItem={"./pii"}
            />
          </div>
        </div >
      </>
    );
  }
}

export default Systems;
