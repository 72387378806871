import React, { Component } from "react";
import { sbudnic2 } from "../images";
import Footer from "../Footer";
import SideToc from "../SideToc";
import * as Icons from "heroicons-react";

class Sbudnic extends Component {
  state = {
    showToc: true,
  };
  toggleToc = (e) => {
    this.setState({ showToc: !this.state.showToc });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const TocItems = [
      {
        header: "Our Goal",
        id: "goal",
        icon: () => <Icons.ArchiveOutline size={22} />,
        // size={24}
      },
      {
        header: "My Role",
        id: "role",
        icon: () => <Icons.PencilOutline />,
      },
      // {
      //   header: "How it Works",
      //   id: "how",
      //   icon: () => <Icons.ClipboardOutline size={22} />,
      // },
      // {
      //   header: "Pull Requests",
      //   id: "pr",
      //   icon: () => <Icons.AnnotationOutline size={22} />,
      // },
      // {
      //   header: "Storyboard",
      //   id: "storyboard",
      //   icon: () => <Icons.StarOutline size={22} />,
      // },
      // {
      //   header: "Conclusion",
      //   id: "conclusion",
      //   icon: () => <Icons.BookmarkOutline size={22} />,
      // },
    ];
    return (
      //       {content.map((item) => (
      //         <div className="container flex flex-col items-center justify-center max-w-2xl py-6 mx-auto leading-7 text-left">
      //         (header !== "" ?){<h1 className=`{item.headerType}`>{item.header}</h1>}
      // </div>
      //       ))}
      <>
        <div className="grid grid-cols-3">
          <SideToc
            TocItems={TocItems}
            toggleToc={this.toggleToc}
            showToc={this.state.showToc}
            className={this.state.showToc ? "md:col-span-1" : "hidden"}
          />
          <div
            className={
              this.state.showToc
                ? "col-span-full md:col-start-2 md:col-end-4"
                : "col-span-full"
            }
          >
            <div className="container flex flex-col items-center justify-center max-w-2xl px-4 py-6 mx-auto leading-7 text-left">
              <h1 className="self-start py-6 text-4xl">SBUDNIC</h1>
              <h2 id="goal" className="self-start py-4 text-xl">
                Our goal: build and launch a stabilized, camera-carrying cubesat
                in months
              </h2>
              <p className="py-4">
                <a
                  className="text-blue-800 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                  href="https://sbudnic.netlify.app"
                >
                  SBUDNIC{" "}
                </a>
                is a group of Brown University students who developed a cube
                satellite using only terrestrial parts like Arduino
                Uno which was launched into orbit in March 2022 on a SpaceX Falcon 9 rocket.
              </p>
              <div className="py-4">
                <div className="p-2 mb-2 text-blue-700 bg-blue-100 border-l-4 border-blue-500">
                  <p className="font-semibold">What was your role in this?</p>
                </div>
                <div>
                  <p>I was a systems engineer and public relations analyst for SBUDNIC.
                    My responsibilities included designing the thermal control system
                    and minimizing radiation damage to electronics.
                    I developed a thermal model of the satellite using Systems
                    Toolkit (STK), while exploring both hardware and software
                    approaches to minimizing radiation damage and managing thermal
                    conditions in space. I also designed and developed the sbudnic
                    website using react, JavaScript, and CSS.
                  </p>
                </div>
              </div>
              <div className="my-4 card">
                <img src={sbudnic2} alt="Systems1" />
              </div>
              <div
                className="py-6"
              >
                <a
                  href="https://sbudnic.space"
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block text-blue-600 transition duration-500 ease-out transform bg-white border-2 border-blue-600 hover:shadow-inner hover:scale-125 btn hover:bg-blue-600 hover:text-white"
                >
                  Visit Sbudnic.space
                </a>
              </div>

              <p>
              </p>

            </div>
            <Footer
              showNext={true}
              prevItem={"./pii"}
              nextItem={"./moba"}
            />
          </div>
        </div >
      </>
    );
  }
}

export default Sbudnic;
