import React, { Component } from "react";
import FileSaver from "file-saver";

class Resume extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const saveFile = () => {
      FileSaver.saveAs(
        process.env.PUBLIC_URL + "/Benjamin_Kilimnik_Resume.pdf",
        "Benjamin_Kilimnik_Resume.pdf"
      );
    };
    return (
      <div className="container flex flex-col items-center justify-center max-w-2xl py-6 mx-auto leading-7 text-left">
        <h1 className="py-16 text-4xl">C.V.</h1>
        <div className="pb-8">
          <button
            onClick={saveFile}
            className="inline-block mx-3 text-blue-500 transition duration-500 ease-out transform bg-white border-2 border-blue-500 hover:shadow-inner hover:scale-125 btn hover:bg-blue-500 hover:text-white"
          >
            Download
          </button>
        </div>
        <div className="w-full h-full px-4 embed-responsive aspect-ratio-4/3">
          <iframe
            title="CV"
            className="embed-responsive-item"
            src="https://drive.google.com/file/d/1nZTeKkij_Td3WAgG7OQ3YLtFazxKEgIE/preview"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Resume;
