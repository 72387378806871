import React, { Component } from "react"
import { Link } from "react-scroll"

class SideToc extends Component {
  // state = {
  //   showToc: true,
  // }

  toggleToc = (e) => {
    // this.setState({ showToc: !this.state.showToc })
    this.props.toggleToc()
  }
  // this.props.sideTocItems --> access the list of toc items

  render() {
    return (
      <div>
        <button
          // onClick={() => {
          //   this.toggleToc()
          //   this.props.parentCallBack()
          // }}
          onClick={this.props.toggleToc}
          className="fixed z-10 hidden p-3 transition duration-500 ease-out transform border-2 border-gray-700 rounded-full shadow-lg cursor-pointer md:block hover:scale-110 bottom-12 left-12 focus:outline-none"
        >
          <svg
            viewBox="0 0 24 24"
            height="1.6em"
            width="1.6em"
            aria-hidden="true"
            focusable="false"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h7v14H4zm9 0V5h7l.001 14H13z"></path>
            <path d="M15 7h3v2h-3zm0 4h3v2h-3z"></path>
          </svg>
        </button>
        <nav
          className={
            this.props.showToc
              ? "md:col-start-2 md:col-end-4 sticky items-end hidden h-screen md:flex-col md:flex top-40 left-40 pr-6"
              : "hidden"
          }
        >
          <ul className="row-span-2">
            {this.props.TocItems.map((item) => {
              /* const IconTag = { tag: () => `Icon.${item.icon}` } */
              const Icon = item.icon
              return (
                <li className="py-2 leading-7 cursor-pointer toc">
                  <Link
                    activeClass="toc-active"
                    to={item.id}
                    spy={true}
                    smooth={true}
                    duration={700}
                    className="flex justify-start text-xl hover:text-blue-500"
                  >
                    {item.header}{" "}
                    <button className="flex flex-col items-center justify-center pb-1 pl-1">
                      <Icon />
                    </button>
                    {/* {React.createElement(`Icons.${item.icon}`)} */}
                  </Link>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    )
  }
}

export default SideToc
