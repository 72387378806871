import React, { Component } from "react";
import { ppa_new_diagram, acl_new_diagram } from "../images";
import Footer from "../Footer";
import SideToc from "../SideToc";
import * as Icons from "heroicons-react";
import Mermaid from "./mermaid/Mermaid";
import mermaid from "mermaid";
import ppa1 from "./mermaid/ppa1";
import ppa2 from "./mermaid/ppa2";
import ppa3 from "./mermaid/ppa3";
import ppa4 from "./mermaid/ppa4";
import ppa5 from "./mermaid/ppa5";
import ppa6 from "./mermaid/ppa6";

class PPA extends Component {
  state = {
    showToc: true,
  };
  toggleToc = (e) => {
    this.setState({ showToc: !this.state.showToc });
  };
  componentDidMount() {
    mermaid.contentLoaded();
    window.scrollTo(0, 0);
  }
  render() {
    const TocItems = [
      {
        header: "The Protocol",
        id: "protocol",
        icon: () => <Icons.LockClosedOutline size={22} />,
        // icon: () => <Icons.ArchiveOutline size={22} />,
        // size={24}
      },
      {
        header: "User Token",
        id: "user_token",
        icon: () => <Icons.KeyOutline />,
      },
      {
        header: "Ad Impression",
        id: "ad_impression",
        icon: () => <Icons.DesktopComputerOutline size={22} />,
      },
      {
        header: "Click & Conversion",
        id: "conversion_token",
        icon: () => <Icons.LightningBoltOutline size={22} />,
      },
      {
        header: "The Payment Promise",
        id: "iou_token",
        icon: () => <Icons.CurrencyDollarOutline size={22} />,
      },
      {
        header: "Achieving Privacy Objectives",
        id: "conclusion",
        icon: () => <Icons.ShieldCheckOutline size={22} />,
      },
      // {
      //   header: "Storyboard",
      //   id: "storyboard",
      //   icon: () => <Icons.StarOutline size={22} />,
      // },
      // {
      //   header: "Conclusion",
      //   id: "conclusion",
      //   icon: () => <Icons.BookmarkOutline size={22} />,
      // },
    ];
    return (
      //       {content.map((item) => (
      //         <div className="container flex flex-col items-center justify-center max-w-2xl py-6 mx-auto leading-7 text-left">
      //         (header !== "" ?){<h1 className=`{item.headerType}`>{item.header}</h1>}
      // </div>
      //       ))}
      <>
        <div className="grid grid-cols-3">
          <SideToc
            TocItems={TocItems}
            toggleToc={this.toggleToc}
            showToc={this.state.showToc}
            className={this.state.showToc ? "md:col-span-1" : "hidden"}
          />
          <div
            className={
              this.state.showToc
                ? "col-span-full md:col-start-2 md:col-end-4"
                : "col-span-full"
            }
          >
            <div className="container flex flex-col items-center justify-center max-w-2xl px-4 py-6 mx-auto leading-7 text-left">
              <h1 className="self-start py-6 text-4xl">
                Privacy Preserving Advertising
              </h1>
              <p className="py-4">
                Today's online advertising landscape involves various stakeholders—advertisers, publishers, platforms, and users—who have conflicting interests and needs. Advertisers want their ads to reach interested users, publishers need to monetize their content, and users want to maintain their privacy. This creates a complex ecosystem with mutual distrust among parties. Privacy Preserving Advertising (PPA) aims to bridge this gap by designing cryptographic protocols and systems that facilitate a privacy-preserving ad ecosystem.
              </p>

              <div className="py-4">
                <div className="p-2 mb-2 text-blue-700 bg-blue-100 border-l-4 border-blue-500">
                  <p className="font-semibold">What is your role in this?</p>
                </div>
                <div>
                  <p>I started working on this project my senior year of college advised by two Professors at Brown University, Malte Schwarzkopf and Anna Lysyanskaya.
                    I helped design the core cryptographic protocol and developed a prototype simulating interactions between advertisers, publishers, platforms, and users.
                    I recruited and supervised an undergraduate collaborator. The project is ongoing and I continue to work in an advisor capacity.
                  </p>
                </div>
              </div>

              <h2 id="protocol" className="self-start py-4 text-3xl">
                The Protocol Explained
              </h2>
              <p className="py-4">
                Our protocol leverages a series of cryptographic tokens to enable anonymous interactions among key stakeholders in the online advertising ecosystem. An overview of the protocol can be seen below, followed by a step by step explanation.
              </p>
              <div className="my-4 card">
                <img src={ppa_new_diagram} alt="PPA protocol diagram" />
              </div>
              <div className="my-4 card">
                <img src={acl_new_diagram} alt="ACL protocol diagram" />
              </div>

              <h3 id="user_token" className="self-start py-4 text-2xl">
                🔑 User Token: The Anonymous Credential (0)
              </h3>
              <p className="py-4">
                We begin with a User Token, a digital credential that validates genuine users. Using
                <a
                  className="text-blue-800 hover:text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                  href="https://dl.acm.org/doi/10.1145/1030083.1030103" >
                  {" "}Direct Anonymous Attestation
                </a>
                , the platform pre-seeds a user's device with this token, certifying that it is not a sybil.
              </p>
              <div className="w-9/12 my-4">
                <Mermaid chart={ppa1} />
              </div>

              <h3 id="ad_impression" className="self-start py-4 text-2xl">
                🎯 Ad Impression: The Anonymous Encounter (Blue 1-5)
              </h3>
              <p className="py-4">
                When a user accesses content from a publisher, the platform issues a Publisher Token. This token helps prevent fraudulent activity on the part of the user by detecting if the identity of the publisher has been fabricated or otherwise tampered with.
              </p>
              <div className="w-9/12 my-4">
                <Mermaid chart={ppa2} />
              </div>
              <p className="py-4">
                The user then submits their User and Publisher Tokens to the platform and receives both an Ad for display and an Ad Impression Token signed by the platform. This token serves as evidence that the ad was issued for the publisher, who may later seek payment. In the process, the platform transfers the publisher's identity, which was stored as a hidden attribute, from the Publisher Token to the Ad Impression Token.
              </p>
              <div className="w-9/12 my-4">
                <Mermaid chart={ppa3} />
              </div>

              <h3 id="conversion_token" className="self-start py-4 text-2xl">
                🖥️ Click & Conversion: The Anonymous Transaction (Green 6-7)
              </h3>
              <p className="py-4">
                Post ad engagement, the user's browser redirects to the advertiser's domain. Importantly, the ad's origin, i.e., the publisher, remains undisclosed to the advertiser. While the platform stays uninformed about user interactions with the ad, the user device forwards the Ad Impression Token to the advertiser, which verifies the platform's signature on it. A Conversion Token is then generated, capturing the essence of the Ad Impression Token, and is signed by the advertiser. The user's client browser receives this Conversion Token, which is sent to the platform for validation, confirming that a legitimate transaction has occurred.
              </p>
              <div className="w-9/12 my-4">
                <Mermaid chart={ppa4} />
              </div>

              <h3 id="iou_token" className="self-start py-4 text-2xl">
                💰 IOU Token: The Payment Promise (Purple 8-10, Amber 11-14)
              </h3>
              <p className="py-4">
                Upon validation of a Conversion Token, the platform issues an IOU token to the client browser. The IOU token embodies the platform's commitment to pay the publisher upon redemption. The publisher's identity is inherited from the Conversion Token and embedded into the IOU token.
              </p>
              <div className="w-9/12 my-4">
                <Mermaid chart={ppa5} />
              </div>
              <p className="py-4">
                After receiving the IOU token from the user, the publisher can redeem it with the platform for payment. The platform then charges the advertiser using the previously received Conversion Token, completing the payment cycle.
              </p>
              <div className="w-9/12 my-4">
                <Mermaid chart={ppa6} />
              </div>

              <h3 id="conclusion" className="self-start py-4 text-2xl">
                🛡 Achieving Privacy Objectives
              </h3>
              <p className="py-4">
                Throughout this process, the identity of the user is never fully disclosed to the platform or advertiser, thanks to the use of cryptographic tokens. Moreover, publishers and advertisers remain anonymous to each other, and the platform can only identify broad events (like ad impressions or conversions), not the specific details (like which user or content was involved). This architecture achieves robust privacy protection for all parties involved.
                Note that the code for this project is currently private, as it still in the early research stages.
              </p>

            </div>
            <Footer
              showNext={true}
              prevItem={"./sbudnic"}
              nextItem={"./systems"}
            />
          </div>
        </div>
      </>
    );
  }
}

export default PPA;
