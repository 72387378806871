const ppa2 = `
%%{init: {'mirrorActors': true, 'height':38, 'width': 35}}%%
sequenceDiagram
    Participant User
    Participant Publisher
    Participant Platform
    Platform->>Publisher: issue publisher_token
    Note over Platform,Publisher: publisher_token: hidden_publisher_id
    User->>Publisher: visits
    Publisher->>User: send publisher_token
    
    `;

export default ppa2;