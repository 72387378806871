const ppa6 = `
%%{init: {'mirrorActors': true, 'height':38, 'width': 35}}%%
sequenceDiagram
    Participant Publisher
    Participant Platform
    Participant Advertiser
    Publisher->>Platform: send iou_token for redemption
    Platform->>Advertiser: send conversion_token
    Advertiser->>Platform: pays three_coins
    Platform->>Publisher: pays two_coins
    
    `;

export default ppa6;