import React, { Component } from "react";
import { pii_blog } from "../images";
import Footer from "../Footer";
import SideToc from "../SideToc";
import * as Icons from "heroicons-react";
import mermaid from "mermaid";

class PII extends Component {
  state = {
    showToc: true,
  };
  toggleToc = (e) => {
    this.setState({ showToc: !this.state.showToc });
  };
  componentDidMount() {
    mermaid.contentLoaded();
    window.scrollTo(0, 0);
  }
  render() {
    const TocItems = [
      {
        header: "Blog",
        id: "blog",
        icon: () => <Icons.DocumentTextOutline size={22} />,
        // icon: () => <Icons.ArchiveOutline size={22} />,
        // size={24}
      },
      {
        header: "ML Model",
        id: "ml",
        icon: () => <Icons.CloudOutline />,
      },
      {
        header: "PII Dataset",
        id: "dataset",
        icon: () => <Icons.DatabaseOutline size={22} />,
      },
    ];
    return (
      //       {content.map((item) => (
      //         <div className="container flex flex-col items-center justify-center max-w-2xl py-6 mx-auto leading-7 text-left">
      //         (header !== "" ?){<h1 className=`{item.headerType}`>{item.header}</h1>}
      // </div>
      //       ))}
      <>
        <div className="grid grid-cols-3">
          <SideToc
            TocItems={TocItems}
            toggleToc={this.toggleToc}
            showToc={this.state.showToc}
            className={this.state.showToc ? "md:col-span-1" : "hidden"}
          />
          <div
            className={
              this.state.showToc
                ? "col-span-full md:col-start-2 md:col-end-4"
                : "col-span-full"
            }
          >
            <div className="container flex flex-col items-center justify-center max-w-2xl px-4 py-6 mx-auto leading-7 text-left">
              <h1 className="self-start py-6 text-4xl">
                Detect sensitive data using NLP
              </h1>
              <blockquote className="self-start w-full p-2 py-2 my-4 italic font-semibold text-gray-700 bg-gray-100 border-l-4 border-gray-400">
                <p className="">"Ah," you think to yourself. "I probably shouldn't be seeing this."</p>
              </blockquote>

              <p className="mb-2 leading-relaxed">
                How often in your career have you muttered these words? With so much personal data flowing through applications, it can be all too easy to chance upon sensitive information while debugging issues.
                To prevent privacy leaks while retaining useful information, developers need a system that finds and redacts only the sensitive parts of each data sample.
                Recent breakthroughs in natural language processing (NLP) have made PII detection and redaction in unseen datasets feasible.
              </p>

              <div className="py-4">
                <div className="p-2 mb-2 text-blue-700 bg-blue-100 border-l-4 border-blue-500">
                  <p className="font-semibold">What was my role in this?</p>
                </div>
                <div>
                  <p>
                    While working on a Kubernetes observability tool called <a href="https://www.px.dev/" target="_blank" rel="noreferrer" className="font-semibold text-blue-700">Pixie</a>,
                    I developed a machine learning architecture to detect and redact 60 types of Personally Identifiable Information (PII) from logs and network data to help developers track the flow of PII in their applications, detect data exfiltration, and better comply with privacy regulations.
                    To help train the model, I built a synthetic data generator that draws from over 3,800 APIs to produce synthetic network traffic.
                  </p>
                </div>
              </div>

              <h2 id="blog" className="self-start py-4 text-3xl">
                Blog Post
              </h2>

              <a id="blog" href="https://blog.px.dev/detect-pii/" target="_blank" rel="noreferrer" className="block overflow-hidden transition-transform transform bg-white rounded-lg shadow-lg hover:scale-105">
                {/* Image for the card */}
                <img src={pii_blog} alt="Descriptive alt text" className="w-full h-auto" />

                {/* Card content */}
                <div className="p-6">
                  <h3 className="mb-2 text-xl font-semibold">
                    I shouldn't be seeing this: anonymize sensitive data while debugging using NLP
                  </h3>
                  <p className="mb-4 text-gray-600">
                    It's 10 pm and you're on-call. A few minutes ago, you received a slack message about performance issues affecting users of your application. You sigh…
                  </p>
                  <div className="flex items-center mb-4">
                    <img src="https://www.gravatar.com/avatar/c1051a44a58eea99d35692ff3dad3f29" alt="Author's Avatar" className="w-8 h-8 mr-3 rounded-full" />
                    <div>
                      <span className="block text-sm font-medium">Benjamin Kilimnik</span>
                      <span className="block text-xs text-gray-500">Nov 04, 2022 • 8 minutes read</span>
                    </div>
                  </div>
                </div>
              </a>

              <div className="flex w-full py-4">
                {/* ML Model Button */}
                <a
                  id="ml"
                  href="https://huggingface.co/spaces/beki/pii-anonymizer"
                  target="_blank"
                  rel="noreferrer"
                  className="flex-1 px-6 py-3 my-2 text-lg text-center text-white transition duration-500 ease-out transform bg-blue-500 rounded hover:scale-110 hover:bg-blue-600"
                >
                  Try ML Model
                </a>

                {/* PII Dataset Button */}
                <a
                  id="dataset"
                  href="https://huggingface.co/datasets/beki/privy"
                  target="_blank"
                  rel="noreferrer"
                  className="flex-1 px-6 py-3 my-2 ml-4 text-lg text-center text-white transition duration-500 ease-out transform bg-green-500 rounded hover:scale-110 hover:bg-green-600"
                >
                  Explore PII Dataset
                </a>
              </div>


            </div>
            <Footer
              showNext={true}
              prevItem={"./spectrum"}
              nextItem={"./abtesting"}
            />
          </div>
        </div >
      </>
    );
  }
}

export default PII;
