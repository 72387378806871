const ppa3 = `
%%{init: {'mirrorActors': true, 'height':38, 'width': 35}}%%
sequenceDiagram
    Participant User
    Participant Platform
    User->>Platform: send publisher_token & user_token
    Platform->>User: issue ad_impression_token, send Ad
    Note over User,Platform: ad_impression_token: hidden_publisher_id, ad
    
    `;

export default ppa3;